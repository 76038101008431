
class ZIDXAccountSiteAPI implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountSiteAPIContainer";
    }
    getMatchURL(){
        return "/account/sites/api-key";
    }
    render(){
        ZIDX.$("#zidxSiteIDCopy").on("click", function(e:ClickEvent){
            e.preventDefault();
            let input=document.getElementById("zidxSiteIdInput")!;
            ZIDX.$(input).trigger("select");
            try {
                let successful = document.execCommand('copy');
            } catch (err) {
                alert('Copy to clipboard is disabled by your browser.');
            }
        });
        ZIDX.$("#zidxHiddenAPIKeyCopy").on("click", function(e:ClickEvent){
            e.preventDefault();
            let input=document.getElementById("zidxHiddenAPIKeyInput")!;
            ZIDX.$(input).trigger("select");
            try {
                let successful = document.execCommand('copy');
            } catch (err) {
                alert('Copy to clipboard is disabled by your browser.');
            }
        });
    }
}